<template>
  <div v-if="!isTutorialOpened && visible" class="z-10 flex w-full items-center gap-4 md:hidden">
    <div
      class="box-border flex w-full rounded-2xl border border-solid border-[#33FF991A] bg-[#14281e] [&>div>a]:!px-1 [&>div>a]:md:!px-4"
      :class="
        opened
          ? 'fixed -bottom-2 left-0 rounded-bl-none rounded-br-none border-[#202020] bg-[#191919]'
          : ''
      "
    >
      <div
        class="flex w-full items-center justify-between gap-2 p-4"
        :class="opened ? 'flex-col' : ''"
        @click="handleOpen"
      >
        <div class="larken flex items-center gap-2 text-base font-medium">
          <span class="larken text-white" :class="opened ? 'hidden' : ''"> New here? </span>
          <span class="larken text-[#33FF99]" :class="opened ? 'hidden' : ''">
            Start tutorial!
          </span>
        </div>
        <span class="larken mr-auto leading-tight text-white" :class="opened ? 'block' : 'hidden'">
          Have you used a decentralized exchange before?
        </span>
        <span class="mr-auto text-[10px] text-[#33FF99]/50" :class="opened ? 'block' : 'hidden'">
          Start tutorial!
        </span>
        <button
          class="rounded-lg bg-[#33FF99]/10 p-1.5"
          :class="opened ? 'hidden' : ''"
          @click="handleHide"
        >
          <Icon class="text-xs text-[#33FF99]/50" icon="humbleicons:times" />
        </button>
        <div class="w-full gap-2.5" :class="opened ? 'flex' : 'hidden'">
          <button
            class="grid w-full grid-cols-[auto_1fr] items-center gap-x-2 rounded-[14px] border border-solid border-[#33FF991A] bg-[#14281e] px-3 py-2.5 text-left text-white"
            @click="handleHide"
          >
            <Icon class="row-span-2 text-2xl text-[#33FF99]" icon="ic:round-check" />
            <span class="text-xs">Yes</span>
            <span class="text-[10px]">Use Dexes often</span>
          </button>
          <router-link
            to="/tutorial/basics"
            class="grid w-full grid-cols-[auto_1fr] items-center gap-x-2 rounded-[14px] border border-solid border-[#E841421A] bg-[#271616] px-3 py-2.5 text-left text-white"
          >
            <Icon class="row-span-2 text-2xl text-[#E84142]" icon="humbleicons:times" />
            <span class="text-xs">No</span>
            <span class="text-[10px]">First time here</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const visible = ref(true)
const opened = ref(false)

const handleHide = (e: MouseEvent) => {
  e.preventDefault()
  visible.value = false
}
const handleOpen = (e: MouseEvent) => {
  e.preventDefault()
  opened.value = true
}

const route = useRoute()

const isTutorialOpened = computed(
  () => route.path.startsWith('tutorial') || route.path.startsWith(`/tutorial`),
)
watch(isTutorialOpened, () => {
  visible.value = false
})
</script>
